import React from 'react';
import Layout from '../components/layout/Layout';
// import LabelText from '../components/LabelText';
import CustomerCard from '../components/CustomerCard';
import FAQ from '../components/FAQ'
import { Helmet } from "react-helmet"
import './services.css'
import AppDev from "../components/App_dev"
import { Link } from "gatsby"

import {
  // AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  // ReceiptRefundIcon,
  UsersIcon,
  AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon
} from '@heroicons/react/outline'
import customerData from '../data/customer-data';

import { CheckIcon } from '@heroicons/react/solid'
import { LaptopChromebookOutlined } from '@mui/icons-material';


const actions = [
  {
    title: 'Front end development',
    href: '#',
    icon: LaptopChromebookOutlined,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "Build feature rich, colorful web applications using the reusable, dynamic architecture of React.js, Angular.js, Vue.js",
  },
  {
    title: 'SaaS applications',
    href: '#',
    icon: BadgeCheckIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "We provide services to build products that are multi-tenant, secure & robust",
  },
  {
    title: 'Backend/Node.js applications',
    href: '#',
    icon: UsersIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "Our software engineers build backend services that scale horizontally, APIs that low latent & highly performant",
  },
  {
    title: 'WebApp development services',
    href: '#',
    icon: CashIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "Build backend web applications across order management, marketplace, logistics, inventory, search",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const Offerings = () => {
  return (
    <>
    <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl text-center pb-4">
            <span className="block text-indigo-600 xl:inline">Offerings</span>
          </h1>
    <div className="rounded-lg overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={classNames(
            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
            'm-2 card shadow relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-indigo-100'
          )}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white'
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" style={{width: '50px', height: '50px'}}/>
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <a href={action.href} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {action.title}
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              {action.cardText}
            </p>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}



export default function Example() {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Web App Development | Web Development Company | Hire Web developers India - C4Scale</title>
          <meta name="description" content="Web development &amp; web developer services at C4Scale" />
        </Helmet>
      <div className="relative bg-gray-50 overflow-hidden">
        <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
          <div className="relative h-full max-w-7xl mx-auto">
            <svg
              className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
            </svg>
          </div>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Web application development </span>{' '}
                <span className="block text-indigo-600 xl:inline"> that scale</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Modern applications need modern web frameworks to build progressive, responsive web apps that scale.
            </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <Link
                    to="/#get-started"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="sectionWrapper">
        {Offerings()}
      </div>
      <AppDev/>
  
  
    </Layout>
  )
}
